<template>
  <div
    ref="target"
    class="relative rounded-full border-2 border-navy bg-white"
    :class="[!!whiteBarHeight ? `${whiteBarHeight}` : 'h-6']"
  >
    <div class="absolute bottom-0 left-0 right-[25px] top-0">
      <div
        class="absolute top-1/2 -translate-y-1/2 rounded-full bg-purple"
        :class="[
          !!progressBallCircle ? `${progressBallCircle}` : 'h-[21px] w-[21px]',
        ]"
        :style="{ left: `${output}%` }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { TransitionPresets } from "@vueuse/core";

interface BasicProgressBarProps {
  value: number;
  delay?: number;
  whiteBarHeight?: string;
  progressBallCircle?: string;
}

const { value, delay, whiteBarHeight, progressBallCircle } =
  defineProps<BasicProgressBarProps>();

const target = ref(null);
const targetIsVisible = useElementVisibility(target);

const source = ref(0);

const output = await useTransition(source, {
  duration: 1500,
  delay: delay ?? 0,
  transition: TransitionPresets.easeInOutCubic,
});

watch(targetIsVisible, () => {
  source.value = value;
});
</script>
