<template>
  <div
    class="flex flex-col gap-4 rounded-2xl bg-white p-5 shadow-sm md:shadow-md"
  >
    <div class="flex gap-2">
      <div class="h-fit min-w-fit rounded-xl bg-navy shadow-sm">
        <NuxtImg
          :src="img"
          sizes="20vw md:20vw"
          :class="
            twMerge(
              'aspect-square w-[64px] rounded-xl object-cover shadow-sm',
              avatarW,
            )
          "
          :alt="name"
        />
      </div>

      <div class="flex min-h-[84px] flex-col gap-1">
        <span
          :class="
            twMerge(
              'font-Kontesa font-bold uppercase',
              titleMargin,
              'title-style',
            )
          "
          >{{ name }}</span
        >
        <span class="text-p1 font-medium text-navy/50">{{ role }}</span>
      </div>
    </div>

    <div class="border-b border-dashed border-navy" />

    <p class="text-p1 font-medium text-navy">{{ desc }}</p>
  </div>
</template>

<script setup lang="ts">
import { twMerge } from "tailwind-merge";

type Props = {
  img: string;
  name: string;
  role: string;
  desc: string;
  bigImg?: boolean;
};

const { img, name, role, desc, bigImg } = defineProps<Props>();

const avatarW = computed(() => (bigImg ? "md:w-[163px]" : "md:w-[85px]"));
const titleMargin = computed(() => (bigImg ? "mt-3" : "mt-0"));
</script>

<style scoped>
.title-style {
  @apply text-h3-mobile text-navy;
}
</style>
